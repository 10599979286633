import React from "react";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Header from "../../CommonCompounds/Header/Header.jsx";
import Ootylisting from "../../Components/OotyListing/OotyListing.jsx";


function listingPage(){
    return(
        <>
        <Header />
        <Ootylisting />
        <Footer />
        </>
    )
}

export default listingPage;