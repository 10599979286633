
// import HomePage from "../Pages/homepage/home_page";


// const router = [

    
//     { path: "/", exact: true, name: "Homepage", element:HomePage  },
     
    

// ]


// export default router;


import Home from "../Pages/homepage/home_page";
import About from "../Pages/aboutus/about_us";
import Contact from "../Pages/contactus/contact_us";
import OotyPackages from "../Pages/ootylisting/ootylisting";


const router = [
    { path: "/", exact: true, name: "Home", element: Home },
    { path: "/about", exact: true, name: "About", element: About },
    { path: "/contact", exact: true, name: "Contact", element: Contact},
    { path: "/OotyPackages", exact: true, name: "ListingPage", element: OotyPackages}

]


export default router;