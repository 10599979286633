import React from "react";
import images from "../../images/images";

const OffersNewsCarousel = () => {
  return (
    <section className="offers-news-section py-5">
      <div className="container text-center">
        <h2>OFFERS AND NEWS</h2>
        <p className="text-center">
          Stay informed about our latest travel offers and important updates to make your next adventure unforgettable!
        </p>

        <div id="offersNewsCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {/* First Slide */}
            <div className="carousel-item active">
              <div className="d-flex">
                <div className="carousel-card">
                  <img src={images.offer1} className="d-block w-100" alt="Contest" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer2} className="d-block w-100" alt="Chikmagalur Package" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer3} className="d-block w-100" alt="Gift Voucher" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer4} className="d-block w-100" alt="Kerala Discount" />
                </div>
              </div>
            </div>

            {/* Second Slide */}
            <div className="carousel-item">
              <div className="d-flex">
                <div className="carousel-card">
                  <img src={images.offer1} className="d-block w-100" alt="Gift Voucher" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer2} className="d-block w-100" alt="Kerala Discount" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer3} className="d-block w-100" alt="Contest" />
                </div>
                <div className="carousel-card">
                  <img src={images.offer4} className="d-block w-100" alt="Chikmagalur Package" />
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Controls */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#offersNewsCarousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#offersNewsCarousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* Custom Styles */}
      <style jsx>{`
        .carousel-inner {
          display: flex;
          overflow: hidden;
        }
        .carousel-card {
          flex: 0 0 24%; /* Adjust for 3.5 cards visible */
          margin-right: 12px;
        }
        .carousel-inner .d-flex {
          gap: 20px;
        }
        .carousel-control-prev,
        .carousel-control-next {
          width: 5%;
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          background-color: rgba(0, 0, 0, 0.5);
        }
      `}</style>
    </section>
  );
};

export default OffersNewsCarousel;
