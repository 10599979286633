import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import images from '../../images/images';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-3 px-3">
            <div className='BookOneTour mt-1 '> BookOneTour </div>
            <p className='pt-3'>
            If you have questions about our services, check out the answers below.  
            For further assistance, don’t hesitate to contact us directly.
            </p>
            <div className="social-icons ">
              <NavLink to="https://www.facebook.com/bookonetours/">
                <FaFacebookF size={30} />
              </NavLink>
              <NavLink to="https://www.instagram.com/bookonetour/">
                <FaInstagram size={30} />
              </NavLink>

            </div>
          </div>

          <div className="col-md-2 mt-4 mx-10">
            <div className='QUICK-links pb-3'>Quick Navigation</div>
            <ul>
              <a href=""><li>Overview</li></a>
              <a href=""><li>Download Itinerary</li></a>
              <a href=""><li>Price Details</li></a>
              <a href=""><li>Sightseeing Itinerary</li></a>
              <a href=""><li>Inclusion & Exclusion</li></a>
              <a href=""><li>Client Reviews</li></a>
              <a href=""><li>FAQ</li></a>
              <a href=""><li>Related Packages</li></a>
            </ul>
          </div>

          <div className="col-md-3 mt-4 px-3 mx-10 ">
            <div className='QUICK-links pb-3'>2N/3D Couple Packages</div>
            <ul>
              <a href="https://coorg2nights3days.bookonetour.in"><li>Coorg</li></a>
              <a href="https://chikmagalur2nights3days.bookonetour.in"><li>Chikmagalur</li></a>
              <a href="https://wayanad2nights3days.bookonetour.in"><li>Wayanad</li></a>
              <a href="https://munnar2nights3days.bookonetour.in"><li>Munnar</li></a>
              <a href="https://kodaikanal2nights3days.bookonetour.in"><li>Kodaikanal</li></a>
              <a href="https://ooty2nights3days.bookonetour.in"><li>Ooty</li></a>
            </ul>
          </div>

          <div className="col-md-3 contact-info mt-4 px-3">
            <div className='QUICK-links pb-3'>Contact Us</div>

            <div className="row">
              <div className="col-md-3 d-flex">
                <img src={images.iconlink2} alt="Mail Icon" className="img-social" />
              </div>
              <div className="col">
                <p><b>Mail</b><br />info@bookonetour.com</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-3 d-flex">
                <img src={images.iconlink3} alt="Phone Icon" className="img-social" />
              </div>
              <div className="col">
                <p><b>Phone</b><br />+91 81488 18018 <br />+91 88701 80018</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-3 d-flex">
                <img src={images.iconlink} alt="Address Icon" className="img-social" />
              </div>
              <div className="col">
                <p><b> Address </b><br />Door No-S-6, KG Casa Blanca -1, 18, Dr. Thirumoorthy Nagar Main Rd, Thirumurthy Nagar, Nungambakkam, Chennai, TamilNadu, 600034. </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
