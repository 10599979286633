import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import images from '../../images/images';
import { NavLink } from 'react-router-dom';


  const packages = [
    {
      id: 1,
      title: "Chikmagalur 3N4D Couple Package",
      description: "Chikmagalur  is a serene hill station in Tamil Nadu, famous for its coffee plantation.....",
      price: "------",
      imageUrl: images.yercaudpack02,
      person: "per person",
      package: "https://chikmagalur2nights3days.bookonetour.in"
    },
    {
      id: 2,
      title: "Manali 3N4D Couple Package",
      description: "Manali is a popular hill station in Himachal Pradesh, renowned for its stunning mount.....",
      price: "------",
      imageUrl: images.manalipack02,
      person: "per person",
      package: "https://chikmagalur2nights3days.bookonetour.in"
    },
    {
      id: 3,
      title: "Ooty 3N4D Couple Package",
      description: "Ooty is a popular hill station in Tamil Nadu, known for its beautiful gardens and.....",
      price: "------",
      imageUrl: images.ootypack02,
      person: "per person",
      package: "https://ooty2nights3days.bookonetour.in"
    },
    {
      id: 4,
      title: "Kodaikanal 3N4D Couple Package",
      description: "Kodaikanal is a beautiful hill station with stunning landscapes and cool weather.",
      price: "------",
      imageUrl: images.kodaikanalpack02,
      person: "per person",
      package: "https://kodaikanal2nights3days.bookonetour.in"
    },
    {
      id: 5,
      title: "Munnar 3N4D Couple Package",
      description: "Munnar is a hill station in Kerala, famous for its tea plantations and lush greenery.....",
      price: "------",
      imageUrl: images.munnarpack02,
      person: "per person",
      package: "https://munnar2nights3days.bookonetour.in"
    },
    {
      id: 6,
      title: "Alleppey 3N4D Couple Package",
      description: "Alleppey, known for its backwaters and houseboats, is a popular tourist.....",
      price: "------",
      imageUrl: images.alleppeypack02,
      person: "per person",
      package: "https://alleppey2nights3days.bookonetour.in"
    },
    {
      id: 7,
      title: "Coorg 3N4D Couple Package",
      description: "Coorg, also known as Kodagu, is a beautiful hill station in Karnataka, famous for.....",
      price: "------",
      imageUrl: images.coorgpack02,
      person: "per person",
      package: "https://coorg2nights3days.bookonetour.in"
    },
    {
      id: 8,
      title: "Wayanad 3N4D Couple Package",
      description: "Wayanad is a picturesque district in Kerala, known for its lush forests, waterfalls.....",
      price: "------",
      imageUrl: images.wayanadpack02,
      person: "per person",
      package: "https://wayanad2nights3days.bookonetour.in"
    },
    {
      id: 9,
      title: "Goa 3N4D Couple Package",
      description: "Goa is famous for its beaches, nightlife, and vibrant culture, making it a favorite.....",
      price: "-----",
      imageUrl: images.goapack02,
      person: "per person",
      package: "https://goa3nights4days-bookonetour.bookonetour.in"
    },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3); // Default to 3 cards for desktop

  useEffect(() => {
    // Adjust visibleCards based on screen width
    const updateVisibleCards = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setVisibleCards(1); // Show 1 card on mobile
      } else if (width < 992) {
        setVisibleCards(2); // Show 2 cards on tablets
      } else {
        setVisibleCards(3); // Show 3 cards on desktop
      }
    };

    updateVisibleCards(); // Initial call
    window.addEventListener('resize', updateVisibleCards); // Add event listener for resize

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateVisibleCards);
  }, []);

  const next = () => {
    setCurrentIndex(prevIndex => (prevIndex + 3) % packages.length); // Loop back to start
  };

  const prev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 3 + packages.length) % packages.length);
  };

  useEffect(() => {
    const intervalId = setInterval(next, 5000); // 5000 milliseconds = 5 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="container my-40">
      <div className="row">
        <div className="col text-center">
          <h2>3N4D Couple Packages</h2>
           <p className="text-center">
            Discover our curated couple packages designed to offer unforgettable experiences.
            Explore options that cater to your preferences and make your moments special.
           </p>
        </div>
      </div>
      <div className="row align-items-center">
        {/* Previous Button */}
        <button
          className="btn col-auto d-flex align-items-center justify-content-center d-none d-md-flex"
          onClick={prev}
          disabled={currentIndex === 0}
          style={{ border: "0px", visibility: visibleCards === 1 ? 'hidden' : 'visible' }}
        >
          <img src={images.leftcircle} alt="Previous" className="hide-mobile" />
        </button>

        {/* Carousel Cards */}
        <div className="col py-4">
          <div className="row">
          {packages.slice(currentIndex, currentIndex + visibleCards).map(pkg => (

  pkg.package ? (
    <a
      href={pkg.package}
      key={pkg.id}
      // target="_blank" // Open in a new tab
      rel="noopener noreferrer" // Security best practice for external links
      className="col-md-4 col-sm-12 col-lg-4 mb-4"
    >
      <div className="carousel__card card">
        <img
          src={pkg.imageUrl}
          alt={pkg.title}
          className="carousel__image card-img-top"
          style={{ width: '100%', height: 'auto' }}
        />
        <div className="carousel__content card-body">
          <h3 className="carousel__package-title">{pkg.title}</h3>
          <p className="carousel__description">{pkg.description}</p>
          <div className='viewpoints-icons'>
            <li><img src={images.package1} alt="" /><br />
              <span className='icon-text'>PRIVATE CAB</span>
            </li>
            <li><img src={images.package2} alt="" /><br />
              <span className='icon-text'>SIGHTSEEING</span>
            </li>
            <li><img src={images.package3} alt="" /><br />
              <span className='icon-text'>HOTEL STAY</span>
            </li>
            <li><img src={images.package4} alt="" /><br />
              <span className='icon-text'>BREAKFAST</span>
            </li>
            <li><img src={images.package5} alt="" /><br />
              <span className='icon-text'>TOUR GUIDE</span>
            </li>
          </div>

          <h3 className="carousel__price">
            {pkg.price} <span className="Person-rate">{pkg.person}</span>
          </h3>
        </div>
      </div>
    </a>
  ) : (
    <NavLink
      to={`/package/${pkg.id}`}
      key={pkg.id}
      className="col-md-4 col-sm-12 col-lg-4 mb-4"
    >
      <div className="carousel__card card">
        <img
          src={pkg.imageUrl}
          alt={pkg.title}
          className="carousel__image card-img-top"
          style={{ width: '100%', height: 'auto' }}
        />
        <div className="carousel__content card-body">
          <h3 className="carousel__package-title">{pkg.title}</h3>
          <p className="carousel__description">{pkg.description}</p>
          <div className='viewpoints-icons'>
            <li><img src={images.package1} alt="" /><br />
              <span className='icon-text'>PRIVATE CAB</span>
            </li>
            <li><img src={images.package2} alt="" /><br />
              <span className='icon-text'>SIGHTSEEING</span>
            </li>
            <li><img src={images.package3} alt="" /><br />
              <span className='icon-text'>HOTEL STAY</span>
            </li>
            <li><img src={images.package4} alt="" /><br />
              <span className='icon-text'>BREAKFAST</span>
            </li>
            <li><img src={images.package5} alt="" /><br />
              <span className='icon-text'>TOUR GUIDE</span>
            </li>
          </div>

          <h3 className="carousel__price">
            {pkg.price} <span className="Person-rate">{pkg.person}</span>
          </h3>
        </div>
      </div>
    </NavLink>
  )
))}

          </div>
        </div>

        {/* Next Button */}
        <button
          className="btn col-auto d-flex align-items-center justify-content-center d-none d-md-flex"
          onClick={next}
          disabled={currentIndex + visibleCards >= packages.length}
          style={{ border: "0px", visibility: visibleCards === 1 ? 'hidden' : 'visible' }}
        >
          <img src={images.Rightcircle} alt="Next" className="hide-mobile" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
