import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import Index from "../../Components/About/About.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

function AboutUs(){
    return(
        <>
        <Header />
        <Index />
        <Footer />
        </>
    )
}

export default AboutUs;
