import React, { useState } from "react";

const Frequently = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <div className="py-40 my-100 " >
      <div className="row text-center">
        <div className="col-md-12 col-lg-12 col-sm-12 ">
          <h2> Frequently Asked Questions </h2>
          <p className="text-center py-2 px-3">
            {" "}
            Below you will find answers to some of the most commonly asked
            questions about our services. If you have any other inquiries, feel
            free to reach out to us.
          </p>
        </div>
      </div>
      <div className="container my-4">
        <div className="accordion">
          {/* Accordion Item 1 */}
          <div className="accordion-item border-none">
            <h2 className="accordion-header ">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(1)}
                aria-expanded={openItem === 1}
              >
                <h3>How do I book a travel package?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 1 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                You can easily book a travel package by browsing through our options, selecting your preferred package, 
                and filling out the booking form. Alternatively, you can contact our team directly by clicking the mobile 
                number in the navigation bar for assistance.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 2 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(2)}
                aria-expanded={openItem === 2}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3>Can I customize my travel package?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 2 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                Yes, all our packages are customizable! You can personalize destinations, accommodations, and activities to suit your preferences.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 3 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(3)}
                aria-expanded={openItem === 3}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3> What payment methods do you accept? </h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 3 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                We currently accept UPI transactions, account transfers, and offline payments. If you need assistance or suggestions for processing payments, our team is happy to guide you. Additional payment gateways will be available soon.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 4 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(4)}
                aria-expanded={openItem === 4}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3>Why choose Book One Tour?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 4 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="py-1">
                With a 4.7-star rating on Google Reviews and over 5,000 successful trips, we provide personalized travel packages tailored to your needs. Our expert agents ensure you get the best deals and a hassle-free booking experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequently;
