import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Contact from "../../Components/Contact/Contact.jsx";

function AboutUs(){
    return(
        <>
        <Header />
        <Contact />
        <Footer />
        </>
    )
}

export default AboutUs;
