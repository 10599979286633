import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import images from '../images/images';

const packages = [
  {
    id: 1,
    title: "La Blooms",
    description: "2N3D Ooty Sightseeing",
    price: "₹4,999",
    imageUrl: images.lablooms,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/Lablooms",
  },
  {
    id: 2,
    title: "Kottaram Residency",
    description: "2N3D Ooty Sightseeing",
    price: "₹4,999",
    imageUrl: images.kottaramresidency,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/kottaram",
  },
  {
    id: 3,
    title: "Central Residency",
    description: "2N3D Ooty Sightseeing",
    price: "₹4,999",
    imageUrl: images.centralresidency,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/centralresidency",
  },
  {
    id: 4,
    title: "Silver Lake View",
    description: "2N3D Ooty Sightseeing",
    price: "₹4,999",
    imageUrl: images.silverlakeview,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/lazoSliverlake",
  },
  {
    id: 5,
    title: "The Majestic Crown",
    description: "2N3D Ooty Sightseeing",
    price: "₹5,399",
    imageUrl: images.majestic,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/MajesticCrown",
  },
  {
    id: 6,
    title: "Hotel Ultimate",
    description: "2N3D Ooty Sightseeing",
    price: "₹5,799",
    imageUrl: images.hotelutimate,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/hotelultimate",
  },
  {
    id: 7,
    title: "The Byke Sun Shine",
    description: "2N3D Ooty Sightseeing",
    price: "₹6,250",
    imageUrl: images.bykesunshine,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/bykesunshinegrand",
  },
  {
    id: 8,
    title: "Vinayaga Inn",
    description: "2N3D Ooty Sightseeing",
    price: "₹6,250",
    imageUrl: images.vinayagainn,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/vinayagapoppys",
  },
  {
    id: 9,
    title: "Hotel Grange",
    description: "2N3D Ooty Sightseeing",
    price: "₹6,750",
    imageUrl: images.hotelgrange,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/hotelgrange",
  },
  {
    id: 10,
    title: "Yantra Leisure",
    description: "2N3D Ooty Sightseeing",
    price: "₹6,750",
    imageUrl: images.yantraleisure,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/SinclairRetreat",
  },
  {
    id: 11,
    title: "Delightz Inn",
    description: "2N3D Ooty Sightseeing",
    price: "8,250",
    imageUrl: images.delightzinn,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/yantraleisure",
  },
  {
    id: 12,
    title: "Kluney Manor",
    description: "2N3D Ooty Sightseeing",
    price: "₹8,250",
    imageUrl: images.kluneymonar,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/kluneyManor",
  },
  {
    id: 13,
    title: "Sinclair's Retreat",
    description: "2N3D Ooty Sightseeing",
    price: "₹9,500",
    imageUrl: images.sinclairsretreat,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/sinclairretreat",
  },
  {
    id: 14,
    title: "Mountain Retreat",
    description: "2N3D Ooty Sightseeing",
    price: "₹9,500",
    imageUrl: images.mountainretreat,
    person: "per person",
    package: "https://ootytourpackages.bookonetour.in/mountainretreat",
  },
  // Add other package objects here...
];

const OotyCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);
  const touchStartX = useRef(0);

  useEffect(() => {
    const updateVisibleCards = () => {
      const width = window.innerWidth;
      if (width < 768) setVisibleCards(1);
      else if (width < 992) setVisibleCards(2);
      else setVisibleCards(3);
    };

    updateVisibleCards();
    window.addEventListener('resize', updateVisibleCards);
    return () => window.removeEventListener('resize', updateVisibleCards);
  }, []);

  const next = () => setCurrentIndex((prev) => (prev + 1) % packages.length);
  const prev = () => setCurrentIndex((prev) => (prev - 1 + packages.length) % packages.length);

  const handleTouchStart = (e) => (touchStartX.current = e.touches[0].clientX);
  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    if (touchEndX - touchStartX.current > 50) prev();
    if (touchStartX.current - touchEndX > 50) next();
  };

  return (
    <div className="container pb-4 pt-4">
      <div className="row">
        <div className="col text-center">
          <h2>OOTY HOTELS WITH TOUR PACKAGE</h2>
          <p className='text-center'>
          Discover our curated couple packages designed to offer unforgettable experiences. Explore options that cater to your preferences and make your moments special.
          </p>
        </div>
      </div>

      <div
        className="carousel row align-items-center"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {/* Navigation Buttons */}
        <button className="btn col-auto d-none d-md-block" onClick={prev} style={{ border: "none" }}>
          <img src={images.leftcircle} alt="Previous" />
        </button>

        {/* Carousel Cards */}
        <div className="col py-4">
          <div className="row">
            {packages.slice(currentIndex, currentIndex + visibleCards).map((pkg) => (
              <a
                href={pkg.package}
                key={pkg.id}
                className="col-md-4 col-sm-12 col-lg-4 mb-4"
                rel="noopener noreferrer"
              >
                <div className="carousel__card card shadow-sm">
                  <img
                    src={pkg.imageUrl}
                    alt={pkg.title}
                    className="carousel__image card-img-top"
                  />
                  <div className="carousel__content card-body">
                    <h3 className="carousel__package-title">{pkg.title}</h3>
                    <p className="carousel__description">{pkg.description}</p>
                    <div className="viewpoints-icons d-flex justify-content-between">
                      <li><img src={images.package1} alt="Cab" /><br /><span className="icon-text">PRIVATE CAB</span></li>
                      <li><img src={images.package2} alt="Sightseeing" /><br /><span className="icon-text">SIGHTSEEING</span></li>
                      <li><img src={images.package3} alt="Hotel" /><br /><span className="icon-text">HOTEL STAY</span></li>
                      <li><img src={images.package4} alt="Breakfast" /><br /><span className="icon-text">BREAKFAST</span></li>
                      <li><img src={images.package5} alt="Guide" /><br /><span className="icon-text">TOUR GUIDE</span></li>
                    </div>
                    <h3 className="carousel__price mt-3">
                      {pkg.price} <span className="text-muted">{pkg.person}</span>
                    </h3>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>

        <button className="btn col-auto d-none d-md-block" onClick={next} style={{ border: "none" }}>
          <img src={images.Rightcircle} alt="Next" />
        </button>
      </div>
    </div>
  );
};

export default OotyCarousel;
