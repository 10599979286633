
import Logo from "../Assets/images/logo.png";
import luggage from "../Assets/images/luggage 1.svg";
import booking from "../Assets/images/booking 1.svg";
import customer from "../Assets/images/best-customer-experience 1.svg";
import reward from "../Assets/images/reward 1.svg";
import Group from "../Assets/images/Group 1341.svg";
import package1 from "../Assets/images/a (1).png";
import package2 from "../Assets/images/a (2).png";
import package3 from "../Assets/images/a (3).png";
import package4 from "../Assets/images/a (4).png";
import package5 from "../Assets/images/a (5).png";
import point from "../Assets/images/point.svg";
import download from "../Assets/images/download.svg";
import iconlink from "../Assets/images/Icon Frame.svg";
import iconlink2 from "../Assets/images/mail.svg";
import iconlink3 from "../Assets/images/icons link.svg";
import Google from "../Assets/images/Google.png";
import CouplePackage from "../Assets/images/Rectangle 21.png";

import Rightcircle from "../Assets/images/Right circle.svg"
import leftcircle from "../Assets/images/Left circle.svg";
import green from "../Assets/images/green.svg";
import CouplePackage2 from "../Assets/images/Couple Package 3 (1).svg";
import CouplePackage3 from  "../Assets/images/Couple Package 3 (2).svg";
import person1 from "../Assets/images/person.png";
import person2 from "../Assets/images/person2.png";
import person3 from "../Assets/images/person3.png";
import Packagedownload  from  "../Assets/images/package download.svg";

import wayanadpack from "../Assets/images/wayanadpackage.png";
import manalipack from "../Assets/images/manalipackage.png";
import coorgpack from "../Assets/images/coorgpackage.png";
import chikpack from "../Assets/images/chikpackage.png";
import kodaikanalpack from "../Assets/images/kodaikanalpackage.png";
import munnarpack from "../Assets/images/munnarpackage.png";
import yercaudpack from "../Assets/images/yercaudpackage.png";
import ootypack from "../Assets/images/ootypackage.png";
import alleppeypack from "../Assets/images/alleppeypackage.png";
import vagamonpack from "../Assets/images/vagamonpackage.png";
import goapack from "../Assets/images/goapackage.png";

import wayanadpack02 from "../Assets/images/wayanadpackage02.png";
import manalipack02 from "../Assets/images/manalipackage02.png";
import coorgpack02 from "../Assets/images/coorgpackage02.png";
import chikpack02 from "../Assets/images/chikpackage02.png";
import kodaikanalpack02 from "../Assets/images/kodaikanalpackage02.png";
import munnarpack02 from "../Assets/images/munnarpackage02.png";
import yercaudpack02 from "../Assets/images/yercaudpackage02.png";
import ootypack02 from "../Assets/images/ootypackage02.png";
import alleppeypack02 from "../Assets/images/alleppeypackage02.png";
import vagamonpack02 from "../Assets/images/vagamonpackage02.png";
import goapack02 from "../Assets/images/goapackage02.png";

import herobannerimg from "../Assets/images/herobannerimg.png";
import ootydestination from "../Assets/images/ootydestination.png";
import kodaikanaldestination from "../Assets/images/kodaikanaldestination.png";
import chikmagalurdestination from "../Assets/images/chikmagalurdestination.png";
import wayanddestination from "../Assets/images/wayanddestination.png";
import alleppeydestionation from "../Assets/images/alleppeydestionation.png";
import vagamondestination from "../Assets/images/vagamondestination.png";
import munnardestination from "../Assets/images/munnardestination.png";
import coorgdestination from "../Assets/images/coorgdestination.png";


import offer1 from "../Assets/images/offer1.jpg";
import offer2 from "../Assets/images/offer2.jpg";
import offer3 from "../Assets/images/offer3.jpg";
import offer4 from "../Assets/images/offer4.jpg";


import destinations from "../Assets/images/destinations.svg";
import packages from "../Assets/images/packages.svg";
import assistance from "../Assets/images/assistance.svg";
import happyclients from "../Assets/images/happyclients.svg";
import bookings from "../Assets/images/bookings.svg";
import experience from "../Assets/images/experience.svg";

import ootybanner from "../Assets/images/ootybanner.jpg";

import offersimg from "../Assets/images/offersimg.svg";

import ootycarousel01 from "../Assets/images/carouselbanner01.jpg";
import ootycarousel02 from "../Assets/images/carouselbanner02.jpg";
import ootycarousel03 from "../Assets/images/carouselbanner03.jpg";
import ootycarousel04 from "../Assets/images/carouselbanner04.jpg";

import lablooms from "../Assets/images/lablooms.png";
import kluneymonar from "../Assets/images/kluneymanor.png";
import majestic from "../Assets/images/majesticcrown.png";
import centralresidency from "../Assets/images/centralresidency.png";
import sinclairsretreat from "../Assets/images/sinclaisretreat.png";
import mountainretreat from "../Assets/images/mountainretreat.png";
import hotelutimate from "../Assets/images/hotelultimate.png";
import hotelgrange from "../Assets/images/hotelgrange.png";
import kottaramresidency from "../Assets/images/kottaramresidency.png";
import silverlakeview from "../Assets/images/silverlakeview.png";
import bykesunshine from "../Assets/images/bykesunshine.png";
import yantraleisure from "../Assets/images/yantraleisure.png";
import delightzinn from "../Assets/images/delightzinn.png";
import vinayagainn from "../Assets/images/poppy.png";


const images = {
    logo: Logo,
    luggage:luggage,
    booking:booking,
    customer:customer,
    reward:reward,
    Group:Group,
   package1:package1,
   package2:package2,
   package3:package3,
   package4:package4,
   package5:package5,
   point:point,
   download:download,
   iconlink:iconlink,
   iconlink2:iconlink2,
   iconlink3:iconlink3,
   Google:Google,
   CouplePackage:CouplePackage,

   wayanadpack:wayanadpack,
   manalipack:manalipack,
   coorgpack:coorgpack,
   chikpack:chikpack,
   kodaikanalpack:kodaikanalpack,
   munnarpack:munnarpack,
   yercaudpack:yercaudpack,
   ootypack:ootypack,
   alleppeypack:alleppeypack,
   vagamonpack:vagamonpack,
   goapack:goapack,

   wayanadpack02:wayanadpack02,
   manalipack02:manalipack02,
   coorgpack02:coorgpack02,
   chikpack02:chikpack02,
   kodaikanalpack02:kodaikanalpack02,
   munnarpack02:munnarpack02,
   yercaudpack02:yercaudpack02,
   ootypack02:ootypack02,
   alleppeypack02:alleppeypack02,
   vagamonpack02:vagamonpack02,
   goapack02:goapack02,

//    highprice001:highprice001,
//    highprice002:highprice002,
//    highprice003:highprice003,

   Rightcircle:Rightcircle,
   leftcircle:leftcircle,
   green:green,
   CouplePackage2:CouplePackage2,
   CouplePackage3:CouplePackage3,
   person1:person1,
   person2:person2,
   person3:person3,
   packagedownload:Packagedownload,
   herobannerimg:herobannerimg, 
   ootydestination:ootydestination,
   kodaikanaldestination:kodaikanaldestination,
   chikmagalurdestination:chikmagalurdestination,
   wayanddestination:wayanddestination,
   alleppeydestionation:alleppeydestionation,
   vagamondestination:vagamondestination,
   munnardestination:munnardestination,
   coorgdestination:coorgdestination,
   offer1:offer1,
   offer2:offer2,
   offer3:offer3,
   offer4:offer4,
   destinations:destinations,
   packages:packages,
   assistance:assistance,
   happyclients:happyclients,
   bookings:bookings,
   experience:experience,
   ootybanner:ootybanner,

   offersimg:offersimg,

   ootycarousel01:ootycarousel01,
   ootycarousel02:ootycarousel02,
   ootycarousel03:ootycarousel03,
   ootycarousel04:ootycarousel04,

   kluneymonar:kluneymonar,
   lablooms:lablooms,
   vinayagainn:vinayagainn,
   majestic:majestic,
   silverlakeview:silverlakeview,
   bykesunshine:bykesunshine,
   sinclairsretreat:sinclairsretreat,
   mountainretreat:mountainretreat,
   hotelgrange:hotelgrange,
   hotelutimate:hotelutimate,
   yantraleisure:yantraleisure,
   delightzinn:delightzinn,
   centralresidency:centralresidency,
   kottaramresidency:kottaramresidency,


   
};

export default images;