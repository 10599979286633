import React from 'react'
import images from '../../images/images'

export const Homepage = () => {
  return (
    <div>
        <div className="banner_container container-fluid">
          <div className="container">
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="banner_content ps-2 ps-md-5">
                  <h1>MAKE YOUR TRIP AMAZING WITH US</h1>
                  <p>
                  Customize and book unforgettable vacation packages designed just for you.
                  Experience hassle-free travel with a variety of options at your fingertips.
                  Start your journey today and create lasting memories!
                  </p>
                  <div className="banner_btns">
                    <a href="">KNOW MORE</a>
                    <a href="https://wa.me/8148818018" target="_blank">Contact Now</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img src={images.herobannerimg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-80">
          <div className="popular_section ">
            <h2 className='py-5 '>Our Popular Recommended Locations</h2>
            <div className="location_img_container">
              <div className="single_location">
                <a href="/OotyPackages">
                  <img src={images.ootydestination} alt="" className="img-fluid" />  </a>
                  <div className="location_name">
                    <p>Ooty</p> 
                    <span>Tamilnadu</span>
                </div>
              </div>
              <div className="single_location">
                <a href="https://kodaikanal2nights3days.bookonetour.in">
                  <img src={images.kodaikanaldestination} alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Kodaikanal</p>
                    <span>Tamilnadu</span>
                </div>
              </div>
              <div className="single_location">
              <a href="https://coorg2nights3days.bookonetour.in">
                <img src={images.coorgdestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Coorg</p>
                  <span>Karnataka</span>
                </div>
              </div>
              <div className="single_location">
              <a href="https://chikmagalur2nights3days.bookonetour.in">
                <img src={images.chikmagalurdestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Chikmagalur</p>
                  <span>Karnataka</span>
                </div>
              </div>
              <div className="single_location">
              <a href="https://munnar2nights3days.bookonetour.in">
                <img src={images.munnardestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Munnar</p>
                  <span>Keral</span>
                </div>
              </div>
              <div className="single_location">
              <a href="https://wayanad2nights3days.bookonetour.in">
                <img src={images.wayanddestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Wayanad</p>
                  <span>Kerala</span>
                </div>
              </div>
              <div className="single_location">
                <a href="https://alleppey2nights3days.bookonetour.in">
                <img src={images.alleppeydestionation} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Alleppey</p>
                  <span>Kerala</span>
                </div>
              </div>
              <div className="single_location">
              <a href="https://vagamon2nights3days.bookonetour.in">
                <img src={images.kodaikanaldestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Vagamon</p>
                  <span>Kerala</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Homepage;
