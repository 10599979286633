import React from "react";
import images from "../../images/images";

function Contact() {
  return (
    <>
      <main>
        <div
          className="contanier contact-contanier"
          style={{ marginTop: 100, marginBottom: 100 }}
        >
          <div className="contact-info">
            <h2>Get in touch</h2>
            <p>
              Use our contact form for all information requests or contact us  
              <br /> directly using the contact information below.
            </p>

            <div className="phone-info">
              <p>
                <strong>Contact Numbers :</strong>
              </p>
              <p>+91 81488 18018  ,  +91 88701 80018</p>
            </div>

            <div className="office-info">
              <p>
                <strong>Our Office Location :</strong>
              </p>
              <p>
                Door No-S-6, KG Casa Blanca -1, 18, Dr.Thirumoorthy Nagar Main Rd,  
                <br />Thirumurthy Nagar, Nungambakkam, Chennai, TamilNadu, 600034.  
              </p>
              {/* Embedded Google Map */}
              <div style={{ marginTop: 20 }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15549.817679511916!2d80.23761779257912!3d13.060001962317389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526658ae86566d%3A0x770a7ad5e56587e!2sThirumurthy%20Nagar%2C%20Nungambakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600034!5e0!3m2!1sen!2sin!4v1698728416023!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>

          </div>
          <div className="contact-form">
            <form>
              <label htmlFor="name">
                {" "}
                <p>Name</p>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your Name"
                required=""
              />
              <label htmlFor="email">
                {" "}
                <p>Contact Number </p>
              </label>
              <input
                type="number"
                id="number"
                name="number"
                placeholder="Enter a Contact Number"
                required=""
              />
              <label htmlFor="email">
                {" "}
                <p>Location </p>
              </label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter your Location"
                required=""
              />
              <label htmlFor="email">
                {" "}
                <p>Email </p>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter a valid email address"
                required=""
              />
              <label htmlFor="message">
                {" "}
                <p>Message</p>
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter your message"
                required=""
                defaultValue={""}
              />
              <a
                className="btn btn-line--blue btn-black"
                href=""
                data-wpel-link="internal"
              >
                Submit{" "}
              </a>
            </form>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n     .contact-form {\n    background-color: white;\n  padding: 50px 50px 50px 50px;\n\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n.contact-form input, .contact-form textarea, .contact-form select {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 40px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n\n.contact-info {\n    max-width: 50%;\n}\n\n.contact-info h2, .contact-form h2 {\n    margin-top: 0;\n    color: #333;\n}\n\n.office-info, .phone-info {\n    margin-top: 20px;\n}\n\n.contact-form {\n    max-width: 500px;\n    flex: 1;\n}\n\n.contact-form label {\n    display: block;\n    margin-bottom: 5px;\n    color: #333;\n}\n\n.contact-form input, .contact-form textarea {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.contact-form .terms {\n    display: flex;\n    align-items: center;\n}\n\n.contact-form .terms input {\n    margin-right: 10px;\n}\n\n.contact-form button {\n   \n\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n.contact-contanier {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n\n  align-items: center;\n    margin: 0 auto;\n}\n.contact-form button:hover {\n   background-color: #0aadf0;\n   color: white;\n}\n\n        ",
          }}
        />
      </main>

      <section className="Section-time">
        <div className="container py-4 ">
            <div className="row justify-content-around">
                {/* Experience */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img src={images.experience} alt="Experience Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">15+</span> <br /> EXPERIENCE
                    </h5>
                </div>
                </div>
                

                {/* Packages */}
                <div className="col-md-2 col-6 d-flex align-items-center py-">
                <img src={images.packages} alt="Packages Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">100+</span> <br /> PACKAGES
                    </h5>
                </div>
                </div>

                {/* Assistance */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img
                    src={images.assistance}
                    alt="Destinations Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">24*7</span> <br /> ASSISTANCE
                    </h5>
                </div>
                </div>

                {/* Bookings */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img src={images.bookings} alt="Bookings Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">500+</span> <br /> BOOKINGS
                    </h5>
                </div>
                </div>

                {/* Happy Clients */}
                <div className="col-md-2 col-12 d-flex align-items-center py-4">
                <img
                    src={images.happyclients}
                    alt="Happy Clients Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">1200+</span> <br /> HAPPY CLIENTS
                    </h5>
                </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Contact;
