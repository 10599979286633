import React from "react";
import images from "../../images/images";


function Index() {
  return (
    <>
  <section className="why-bookonetour-section my-80 ">

    <div className="container text-center">
      <div className="row">
        <div className="col-lg-12">
          <h1 >Why Bookonetour For Your Trip</h1>
        </div>
      </div>
    </div>

    <div className="container justify-content-around py-4 ">
            <div className="row ">
                {/* Experience */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-4">
                <img src={images.experience} alt="Experience Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">15+</span> <br /> EXPERIENCE
                    </h5>
                </div>
                </div>

                {/* Destinations */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-4">
                <img src={images.destinations} alt="Experience Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">25+</span> <br /> DESTINATIONS
                    </h5>
                </div>
                </div>

                {/* Packages */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-">
                <img src={images.packages} alt="Packages Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">100+</span> <br /> PACKAGES
                    </h5>
                </div>
                </div>

            
                {/* Assistance */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-4">
                <img
                    src={images.assistance}
                    alt="Destinations Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">24*7</span> <br /> ASSISTANCE
                    </h5>
                </div>
                </div>

                {/* Bookings */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-4">
                <img src={images.bookings} alt="Bookings Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">500+</span> <br /> BOOKINGS
                    </h5>
                </div>
                </div>

                {/* Happy Clients */}
                <div className="col-md-4 col-6 d-flex align-items-center justify-content-center py-4">
                <img
                    src={images.happyclients}
                    alt="Happy Clients Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">1200+</span> <br /> HAPPY CLIENTS
                    </h5>
                </div>
                </div>
            </div>
    </div>

  </section>
    
    </>
  );
}

export default Index;
